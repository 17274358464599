<template>
  <div class="page">
    <div class="files content-wrap">
      <div v-for="item in list" :key="item._id" class="file-item">
        <div class="name">
          {{ item.remark + item.extre }}
        </div>
        <div class="mid" />
        <div class="donwload">
          <a :href="item.url" :download="item.remark + item.extre">点击下载</a>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.file-item {
  display: flex;
  border-bottom: 1px solid #bcbec2;
  padding: 10px 20px;
  .mid{
    flex: 1
  }
}
</style>

<script>
import { getList } from '@/api/file'
export default {
  data() {
    return {
      list: []
    }
  },
  mounted() {
    getList().then(res => {
      this.list = res
    })
  }
}
</script>
